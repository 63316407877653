import style from "./style.module.scss";
import Burger from "../btns/burger/burger";
import Bet from "../btns/bet/bet";
import Win from "../win/win";
import Balance from "../balance/balance";
import Spin from "../btns/spin/normal/spin";
import SpinMobile from "../btns/spin/mobile/spin";
import {useStore} from "effector-react";
import {$store} from "../../store";
import Device from "../../core/device";
import Sound from "../btns/sound/sound";
import Display from "../../core/display";
import Dev from "../btns/dev/dev";

const Layer_GUI = (params:{onSpin:Function}) => {
	const store = useStore($store);
	
	return <div className={`${style.layer_gui} ${store.app.isDev ? style.isDev : null}`}>
		<div className={style.header}>
			<div className={style.left}>
				<Sound/>
				{ !store.app.isLandscape ? <Dev/> : null }
			</div>
			<div className={style.center}>

			</div>
			<div className={style.right}>
				{ Device.isMobile() && <Burger/> }
			</div>
		</div>
		
		<div className={style.body}>
			<div className={style.left}>
				{ Device.isMobile()&&store.app.isLandscape ? <Dev/> : null }
			</div>
			<div className={style.center}>
				{ Device.isMobile()&&!store.app.isLandscape ? <SpinMobile onSpin={params.onSpin}/> : null }
			</div>
			<div className={style.right}>
				{ Device.isMobile()&&store.app.isLandscape ? <SpinMobile onSpin={params.onSpin}/> : null }
			</div>
		</div>
		
		<div className={style.footer}>
			<div className={style.left}>
				{ !Device.isMobile() && <Burger/> }
				<Bet/>
			</div>
			<div className={style.center}>
				{ !Device.isMobile() && <Spin onSpin={params.onSpin}/> }
			</div>
			<div className={style.right}>
				<Win/>
				<Balance/>
			</div>
		</div>
	</div>;
};

export default Layer_GUI;