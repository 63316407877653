import { createStore, createEvent } from "effector";

export type Slots = {
	isSpin: boolean,
	
	collIsSpin: boolean[],
	collIsStopped: boolean[],
	collIsBounced: boolean[],
	mainPortalIsStarting: boolean,
	mainPortalIsBounce: boolean,
	
	spinCount: number,
	spinCounter: number[],
	spinCollCount: number[],
	isStopped: boolean,
	slotCells: {cols:number,rows:number},
	slotElSize: number,
	
	spinPrices: number[],
	spinPriceIndex: number
}

const mock = {
	isSpin: false,
	
	collIsSpin: [],
	collIsStopped: [],
	collIsBounced: [],
	mainPortalIsStarting: false,
	mainPortalIsBounce: false,
	
	spinCount: 5,
	spinCounter: [],
	spinCollCount: [],
	isStopped: true,
	slotCells: {cols:5,rows:4},
	slotElSize: 12,
	
	spinPrices: [.20, .50, 1, 2, 3, 4, 5],
	spinPriceIndex: 0
}

export const setIsSpin = createEvent<boolean>();
export const setSpinPriceIndex = createEvent<number>();
export const setSpinCounter = createEvent<number[]>();
export const setColIsSpin = createEvent<{index:number,state:boolean}>();
export const setCollIsStopped = createEvent<{index:number,state:boolean}>();
export const setCollIsBounced = createEvent<{index:number,state:boolean}>();
export const setMainPortalIsStarting = createEvent<boolean>();
export const setMainPortalIsBounce = createEvent<boolean>();
export const setIsStopped = createEvent<boolean>();
export const clear = createEvent();

export const $slots = createStore<Slots>(mock)
	.on(setIsSpin, (state, isSpin:boolean) => { return {...state, isSpin}; })
	.on(setSpinPriceIndex, (state, index:number) => { state.spinPriceIndex=index; return {...state}; })
	.on(setSpinCounter, (state, spinCounter:number[]) => { return {...state, spinCounter}; })
	.on(setColIsSpin, (state, params:{index:number,state:boolean}) => {
		state.collIsSpin[params.index] = params.state;
		state.isSpin = !!state.collIsSpin.filter((el)=>{return el;}).length;
		return { ...state };
	})
	.on(setCollIsStopped, (state, params:{index:number,state:boolean}) => {
		state.collIsStopped[params.index] = params.state; return { ...state };
	})
	.on(setCollIsBounced, (state, params:{index:number,state:boolean}) => {
		state.collIsBounced[params.index] = params.state; return { ...state };
	})
	.on(setMainPortalIsStarting, (state, mainPortalIsStarting:boolean) => {return { ...state, mainPortalIsStarting }; })
	.on(setMainPortalIsBounce, (state, mainPortalIsBounce:boolean) => { return { ...state, mainPortalIsBounce }; })
	.on(setIsStopped, (state, isStopped:boolean) => { return {...state, isStopped}; })
	.reset(clear);
