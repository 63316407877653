import style from "./style.module.scss";
import {useStore} from "effector-react";
import {$store} from "../../../store";
import {setIsDev} from "../../../store/app";

const Dev = () => {
    const store = useStore($store);

    return <div className={`${style.dev} ${store.app.isDev ? style.active : ''} roboto-condensed-700`} onClick={()=>{ onDevMode(!store.app.isDev) }}>
		<p>DEV</p>
	</div>
	
	function onDevMode(bool:boolean) {
		setIsDev(bool);
	}
};

export default Dev;