import React from "react";
import ReactDOM from "react-dom";

import Main from "./pages/main/main";
import Error_WebGL from "./pages/errors/webgl";
import {createRoot} from "react-dom/client";
import Display from "./core/display";

// @ts-ignore
import WebGL from "three/addons/capabilities/WebGL.js";

Display.startService();

const container = document.getElementById("app")!;
const root = createRoot(container);

root.render(
	WebGL.isWebGLAvailable() && WebGL.isWebGL2Available()
		? <Main/>
		: <Error_WebGL/>
)



if ( WebGL.isWebGLAvailable() ) {

} else {
	console.info("WebGL", WebGL.getWebGLErrorMessage())
}
