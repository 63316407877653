import {setAppIsMobile, setIsLandscape, setIsSmallScreen} from "../store/app";

export default class Display {

	static appMobileWidth = 830;
	static appSmallHeight = 700;

	static startService() {
		window.addEventListener('resize', Display.windowOnResize );
		Display.windowOnResize();
	}

	static windowOnResize() {
		setAppIsMobile(document.documentElement.clientWidth<=Display.appMobileWidth);
		setIsSmallScreen(window.innerHeight<Display.appSmallHeight);
		setIsLandscape(window.innerWidth>window.innerHeight);
	}

	static isFullScreen(state:boolean) {
		const doc = window.document;
		const docEl = doc.documentElement;

		// @ts-ignore
		const requestFullScreen = docEl.requestFullscreen || docEl?.mozRequestFullScreen || docEl?.webkitRequestFullScreen;
		// @ts-ignore
		const cancelFullScreen = doc.exitFullscreen || doc?.mozCancelFullScreen || doc?.webkitExitFullscreen;

		// @ts-ignore
		if(!doc.fullscreenElement && !doc?.mozFullScreenElement && !doc?.webkitFullscreenElement) {
			if(state) requestFullScreen.call(docEl);
		} else {
			if(!state) cancelFullScreen.call(doc);
		}
	}

	static getIsFullscreen() {
		const doc = window.document;
		// @ts-ignore
		return (doc?.fullscreenElement || doc?.mozFullScreenElement || doc?.webkitFullscreenElement);
	}
	
	static getWindowSize = function(withScrollBar:boolean=true) {
		let wid = 0;
		let hei = 0;
		if (typeof window.innerWidth != "undefined") {
			wid = window.innerWidth;
			hei = window.innerHeight;
		}
		else {
			if (document.documentElement.clientWidth == 0) {
				wid = document.body.clientWidth;
				hei = document.body.clientHeight;
			} else {
				wid = document.documentElement.clientWidth;
				hei = document.documentElement.clientHeight;
			}
		}
		return { width: wid - (withScrollBar ? (wid - document.body.offsetWidth + 1) : 0), height: hei };
	};

	static getAspectRatio() {
		const w = document.documentElement.clientWidth;
		const h = document.documentElement.clientHeight;

		let aspectRatio;

		if (w > h) aspectRatio = w / h;
		else aspectRatio = h / w;

		return aspectRatio;
	}

	static isLandscape() {
		const size= Display.getWindowSize();
		return size.width>size.height;
	}
}
