import style from "./style.module.scss";
import spin_back from "../../../../img/btns/spin/spin_back.png";
import arrow from "../../../../img/btns/spin/arrow.png";
import arrow_blur from "../../../../img/btns/spin/arrow_blur.png";

import {setColIsSpin, setIsSpin} from "../../../../store/slots";

import {$store} from "../../../../store";
import {useStore} from "effector-react";
import Utils from "../../../../core/utils";
import {setBalance} from "../../../../store/app";

const Spin = (params:{onSpin:Function}) => {
    const store = useStore($store);
    return <div className={style.spin}>
        <div className={style.icon}>
            <div className={`${style.group} ${store.slots.isSpin ? style.disabled:''}`} onClick={onSpin}>
                <img className={style.back} src={spin_back} alt="bet"/>
                <img className={style.arrow} src={arrow} alt="bet"/>
                <img className={style.blur} src={arrow_blur} alt="bet"/>
            </div>
        </div>
    </div>
    
    function onSpin() {
        params?.onSpin();
    }
};

export default Spin;