import {useLayoutEffect} from "react"

import Utils from "../../core/utils";
import Layer_GUI from "../../components/layers/gui";
import {$store} from "../../store";
import {useStore} from "effector-react";
import {
	setColIsSpin,
	setCollIsBounced,
	setCollIsStopped,
	setIsSpin,
	setIsStopped, setMainPortalIsBounce, setMainPortalIsStarting,
	setSpinCounter
} from "../../store/slots";

import background_theme from "../../sound/background.mp3"
import spin_start from "../../sound/spinStart.mp3"
import spin_col_stop from "../../sound/colStop.mp3"

import {
	Clock,
	Euler,
	FrontSide,
	MathUtils,
	Mesh,
	MeshBasicMaterial,
	OrthographicCamera,
	Plane,
	PlaneHelper,
	Scene,
	TextureLoader,
	Vector3,
	WebGLRenderer
} from "three"

// @ts-ignore
import Stats from 'three/addons/libs/stats.module.js';

import {OrbitControls} from "three/examples/jsm/controls/OrbitControls"

import * as dat from 'dat.gui';
import {GUI} from 'dat.gui';
import Three from "../../core/three";

import style from "./style.module.scss";
import background from "../../img/bg/bg.jpg";

import s_bg from "../../img/slot/bg.png";
import s_cl from "../../img/slot/clever.png";
import s_cm from "../../img/slot/compas.png";
import s_cr from "../../img/slot/crunch.png";
import s_ht from "../../img/slot/heart.png";
import s_pk from "../../img/slot/pick.png";
import {setBackgroundMusik, setSpinColStop, setSpinStart} from "../../store/sound";
import Device from "../../core/device";
import Display from "../../core/display";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import Tonemap from "../../core/tonemap";
import Postprocess from "../../core/postprocess";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {OutputPass} from "three/examples/jsm/postprocessing/OutputPass";
import {setBalance} from "../../store/app";
import {clipping} from "three/examples/jsm/nodes/accessors/ClippingNode";
const textureArr = [s_bg, s_cl, s_cm, s_cr, s_ht, s_pk];

const textureLoader = new TextureLoader();

let scene: Scene, camera: OrthographicCamera, renderer: WebGLRenderer, clock: Clock;
let composer: EffectComposer;
let controls: OrbitControls;
let planeObjects: Mesh[], planeHelpers: PlaneHelper[];
let gui: dat.GUI, stats: Stats;

const mainClippingPlanes = [
	// new Plane(new Vector3(-1, 0, 0), 30),
	new Plane(new Vector3(0, -1, 0), 24),
	// new Plane( new Vector3( 0, 0, - 1 ), 25 ),
	// new Plane(new Vector3(1, 0, 0), 30),
	new Plane(new Vector3(0, 1, 0), 24),
];
const mainClippingInitConstants = [
	mainClippingPlanes[0].constant,
	mainClippingPlanes[1].constant,
]

const slotMainColumns: Mesh[]=[], slotSectionColumns: Mesh[][]=[];
let slotContainer: Mesh

let camaraProps = {
	frustumSize: 60,
	aspect: window.innerWidth / window.innerHeight,
}

let matArr:MeshBasicMaterial[]=[];

const params = {
	animate: true,
	clipping: true,
	mainClippingPlanes: {
		constant: 24,
		negated: false,
		displayHelper: true
	}
};

const mainSpinSpeed = 128;
const SpinBounceDistance = 3;
let SpinStopped = true;
let SpinStarted = false;

let spinCount:number[]=[];
let isReset:boolean[]=[];

export default function Main() {
	const store = useStore($store);

	useLayoutEffect(() => { // =======================================
		if(!store.app.isDev) {
			console.log("EXIT FROM DEV");
			if(camera) {
				controls.reset();
			}
			planeHelpers && planeHelpers?.forEach(ph => { ph.visible = false; });
			gui?.hide();

			const statsDom = document.getElementById('stats');
			if(statsDom) {
				statsDom.style.display = 'none';
			}
		} else {
			console.warn("YOU DEVELOPER");
			planeHelpers && planeHelpers.forEach(ph => { ph.visible = true; });

			if(!gui) gui = new GUI();
			else gui.show();

			if(stats) {
				stats.dom.style.display = 'block';
			} else {
				stats = new Stats();
				stats.dom.id = "stats";
				document.body.appendChild(stats.dom);
			}

			Tonemap.appendGUI(gui,scene);
			Postprocess.appendGUI(gui,scene);
		}
	}, [store.app.isDev]); // ===============================================

	useLayoutEffect(() => {
		const init = () => {
			scene = new Scene();
			clock = new Clock();
			
			// Camera =============================================================
			camera = new OrthographicCamera(camaraProps.frustumSize * camaraProps.aspect / -2, camaraProps.frustumSize * camaraProps.aspect / 2, camaraProps.frustumSize / 2, camaraProps.frustumSize / -2, -1000, 5000);
			camera.position.set(0, 0, 0);
			camera.position.z = -10;
			
			// Audio ==============================================================
			Three.audioInit(camera);
			
			setBackgroundMusik(Three.createSound(background_theme, .3));
			setSpinStart(Three.createSound(spin_start));
			setSpinColStop(Three.createSound(spin_col_stop, 1));
			
			document.addEventListener("click", () => {
				if(Device.isMobile()&&!Device.isIOS()) Display.isFullScreen(true);
				setTimeout(()=>{
					store.sound.sounds.background_musik!.play();
				},1000)
			});
			// ====================================================================
			
			planeHelpers = mainClippingPlanes.map(p => new PlaneHelper(p, p.constant + 50, 0xffffff));
			planeHelpers.forEach(ph => {
				ph.visible = store.app.isDev;
				// slotContainer.add(ph);
			});
			
			renderer = new WebGLRenderer({
				antialias: true,
				logarithmicDepthBuffer: true,
			});
			renderer.setSize(window.innerWidth, window.innerHeight);
			renderer.setPixelRatio(window.devicePixelRatio);
			renderer.setClearColor(0x000);
			renderer.autoClear = false;
			renderer.localClippingEnabled = true;
			
			document.getElementById("main")!.appendChild(renderer.domElement);
			controls = new OrbitControls(camera, renderer.domElement);
			controls.minZoom = .5;
			controls.maxZoom = 2;
			controls.update();

			matArr = textureArr.map((texture) => {
				return new MeshBasicMaterial({
					map: textureLoader.load(texture),
					side: FrontSide,
					clippingPlanes: params.clipping ? mainClippingPlanes : undefined,
					opacity: 1,
					transparent: true,
					depthWrite: false,
				});
			});
			
			window.addEventListener("resize", (e) => {
				onResize(renderer);
			}, false);
			
			Three.SetSceneBackground(scene, background);

			// COMPOSER ===========================================
			composer = new EffectComposer(renderer);
			composer.addPass( new RenderPass( scene, camera ) );
			composer.addPass( new OutputPass() );

			Tonemap.init(renderer, scene, camera, composer);
			Postprocess.init(renderer, scene, camera, composer, gui, render);
			
			updateCamera();
		}
		
		const buildSlotPortal = () => {
			// Build portal
			const containerMaterial = new MeshBasicMaterial({
				color: 0xffffff,
				clippingPlanes: params.clipping ? mainClippingPlanes : undefined,
				visible: store.app.isDev
			});
			
			slotContainer = Three.createPlaneAndAdd(
				scene, containerMaterial, store.slots.slotElSize * store.slots.slotCells.cols, -(store.slots.slotElSize * store.slots.slotCells.rows) * 3,
				new Vector3(0, 0, 0),
				new Euler(0, -0 * MathUtils.DEG2RAD, 0)
			);
			planeHelpers.forEach(ph => {
				slotContainer.add(ph);
			});
			
			// Build main columns
			for(let i in Utils.arrFromCount(store.slots.slotCells.cols)) {
				const mainCol = Three.createPlane(
					new MeshBasicMaterial({
						color: Utils.getRandomHexColor(),
						clippingPlanes: params.clipping ? mainClippingPlanes : undefined,
						visible: store.app.isDev
					}),
					store.slots.slotElSize, -Three.GetMeshSize(slotContainer).y,
					new Vector3(store.slots.slotElSize*(Number(i)-2), 0, -2),
					new Euler(0, -0 * MathUtils.DEG2RAD, 0)
				)
				
				const sections:number = 3;
				for(const j in Utils.arrFromCount(sections)) {
					const sectionCol = Three.createPlane(
						new MeshBasicMaterial({
							color: Utils.getRandomHexColor(),
							clippingPlanes: params.clipping ? mainClippingPlanes : undefined,
							wireframe: true,
							wireframeLinecap: "123",
							visible: store.app.isDev
						}),
						store.slots.slotElSize, -Three.GetMeshSize(slotContainer).y/sections,
						new Vector3(0, Three.GetMeshSize(slotContainer).y/sections*(Number(j)-1), -2),
						new Euler(0, -0 * MathUtils.DEG2RAD, 0)
					)
					if(!slotSectionColumns[j]) slotSectionColumns[j]=[];
					slotSectionColumns[j].push(sectionCol);
					mainCol.add(sectionCol);
				}

				slotMainColumns.push(mainCol);
				slotContainer.add(mainCol);
			}
			
			setStoppedAllColls(true);
			setBounceAllColls(false);
			
			buildSlotElements();
		}

		// BUILD SLOT ELEMENTS ==========================================================
		const buildSlotElements = ()=>{
			buildSlotMainElements();
			buildSlotSecondElements();
		}
		const buildSlotMainElements = ()=>{
			for(const k in slotSectionColumns[1]) {
				slotSectionColumns[1][k].clear();
				for(const j in Utils.arrFromCount(store.slots.slotCells.rows)) {
					const randInt = Math.floor(Math.random()*Object.keys(matArr).length);
					const el = Three.createPlane(
						matArr[randInt], -store.slots.slotElSize, store.slots.slotElSize,
						new Vector3( 0, -store.slots.slotElSize*(Number(j)-1.5), -2 ), // slotElSize*(counter-1.5)
					);
					slotSectionColumns[1][k].add(el)
				}
			}
		}
		const buildSlotSecondElements = ()=>{
			const slotMaterials:MeshBasicMaterial[][]=[];
			for(const x in Utils.arrFromCount(store.slots.slotCells.cols)) {
				for(const y in Utils.arrFromCount(store.slots.slotCells.rows)) {
					if(!slotMaterials[x]) slotMaterials[x]=[];
					const randInt = Math.floor(Math.random()*Object.keys(matArr).length);
					slotMaterials[x][y]=matArr[randInt];
				}
			}
			for(const section in slotSectionColumns) {
				if(Number(section)===1) continue;
				const blockColumns = slotSectionColumns[section]
				for(const x in blockColumns) {
					slotSectionColumns[section][x].clear();
					for(const y in Utils.arrFromCount(store.slots.slotCells.rows)) {
						const el = Three.createPlane(
							slotMaterials[x][y], -store.slots.slotElSize, store.slots.slotElSize,
							new Vector3( 0, -store.slots.slotElSize*(Number(y)-1.5), -2 ),
						);
						slotSectionColumns[section][x].add(el)
					}
				}
			}
		}
		// ==============================================================================
		
		// BUILD SLOT COLL ELEMENTS =====================================================
		const buildSlotCollMainElements = (mainCollIndex:number)=>{
			slotSectionColumns[1][mainCollIndex].clear();
			for(const j in Utils.arrFromCount(store.slots.slotCells.rows)) {
				const randInt = Math.floor(Math.random()*Object.keys(matArr).length);
				const el = Three.createPlane(
					matArr[randInt], -store.slots.slotElSize, store.slots.slotElSize,
					new Vector3( 0, -store.slots.slotElSize*(Number(j)-1.5), -2 ), // slotElSize*(counter-1.5)
				);
				slotSectionColumns[1][mainCollIndex].add(el)
			}
		}
		const buildSlotCollSecondElements = (isReset:boolean[])=>{
			const slotMaterials:MeshBasicMaterial[][]=[];
			for(const x in Utils.arrFromCount(store.slots.slotCells.cols)) {
				for(const y in Utils.arrFromCount(store.slots.slotCells.rows)) {
					if(!slotMaterials[x]) slotMaterials[x]=[];
					const randInt = Math.floor(Math.random()*Object.keys(matArr).length);
					slotMaterials[x][y]=matArr[randInt];
				}
			}
			for(const section in slotSectionColumns) {
				if(Number(section)===1) continue;
				const blockColumns = slotSectionColumns[section]
				for(const x in blockColumns) {
					if(!isReset[x]) continue;
					slotSectionColumns[section][x].clear();
					for(const y in Utils.arrFromCount(store.slots.slotCells.rows)) {
						const el = Three.createPlane(
							slotMaterials[x][y], -store.slots.slotElSize, store.slots.slotElSize,
							new Vector3( 0, -store.slots.slotElSize*(Number(y)-1.5), -2 ),
						);
						slotSectionColumns[section][x].add(el)
					}
				}
			}
		}
		
		// ==============================================================================
		const updateCamera = ()=>{
			const aspectX = window.innerWidth / window.innerHeight;
			const aspectY = window.innerHeight / window.innerWidth;
			if(Display.isLandscape()) {
				camera.left = camaraProps.frustumSize * camaraProps.aspect / - 2;
				camera.right = camaraProps.frustumSize * camaraProps.aspect / 2;
				camera.top = camaraProps.frustumSize / 2;
				camera.bottom = camaraProps.frustumSize / - 2;
			} else {
				camera.left = camaraProps.frustumSize/-1.8;
				camera.right = camaraProps.frustumSize/1.8 ;
				camera.top = camaraProps.frustumSize * aspectY / 3.3;
				camera.bottom = camaraProps.frustumSize  * aspectY/ -1.3;
			}
			camera.updateProjectionMatrix();
		}

		const onResize = (renderer:WebGLRenderer) => {
			setTimeout(()=>{
				camaraProps.aspect = window.innerWidth / window.innerHeight
				renderer.setSize( window.innerWidth, window.innerHeight );
				composer.setSize( window.innerWidth, window.innerHeight );
				Three.UpdateSceneBackground(scene);
				render();
				updateCamera();
			},100);
		}
		
		function animate():void { // ============================
			const delta = clock.getDelta();
			requestAnimationFrame(animate);
			
			if($store.getState().slots.isSpin) {
				onceSpinStart(); // once
				spin(delta);
				stopProcess(delta);
			} else {
				startProcess(delta);
				stopProcess(delta);
			}

			stats?.begin();
			render();
			stats?.end();
		} // ====================================================
		
		
		// PROCESS ==============================================
		const stopProcess = (delta:number)=>{
			
			for(const i in slotMainColumns) {
				const index = Number(i);
				const col = slotMainColumns[index];
				if( !$store.getState().slots.collIsSpin[Number(i)] && !$store.getState().slots.collIsStopped[Number(i)] ) {
					
					if(col.position.y <= -SpinBounceDistance) {
						setCollIsBounced({index:index,state:true});
					}
					
					if(store.slots.collIsBounced[index]) {
						col.translateY(delta * mainSpinSpeed/5);
						if(col.position.y >= 0) {
							setCollIsStopped({index:index,state:true});
							setCollIsBounced({index:index,state:false});
							col.position.setY(0);
						}
					} else col.translateY(-delta * mainSpinSpeed/2);
					
					if( !store.slots.collIsStopped.filter((el)=>!el).length ) {
						onceSpinStop(); // once
					}
				}
			}
		}
		const startProcess = (delta:number)=>{
			
			if(!$store.getState().slots.isSpin && $store.getState().slots.mainPortalIsStarting) {
				if($store.getState().slots.mainPortalIsBounce) {
					
					//planeHelpers[0].translateY(-delta * mainSpinSpeed/1.6)
					slotContainer.translateY(-delta * mainSpinSpeed/1.6);
					slotContainer.updateMatrixWorld(true)
					
					scene.updateMatrixWorld(true)
				} else {
					visibleSecondSections(false);
					
					mainClippingPlanes[0].constant = mainClippingInitConstants[0]+5;
					//planeHelpers[0].translateY(delta * mainSpinSpeed/4)
					slotContainer.translateY(delta * mainSpinSpeed/4);
				}
				if(slotContainer.position.y >= SpinBounceDistance ) {
					setMainPortalIsBounce(true);
				}

				if($store.getState().slots.mainPortalIsBounce && slotContainer.position.y <= 0) {
					mainClippingPlanes[0].constant = mainClippingInitConstants[0];
					
					setMainPortalIsBounce(false);
					setMainPortalIsStarting(false);
					slotContainer.position.setY(0);

					for(const i in Utils.arrFromCount(store.slots.slotCells.cols)) {
						setColIsSpin({index:Number(i),state:true});
					}
					
					setBalance(store.app.balance-store.slots.spinPrices[store.slots.spinPriceIndex]);
					
					setStoppedAllColls(false);
					setBounceAllColls(false);
					visibleSecondSections(true);
					for(const i in Utils.arrFromCount(store.slots.slotCells.cols)) {
						setColIsSpin({index:Number(i),state:true});
					}
				}
			}
		}
		
		const initPos:number = store.slots.slotElSize*(store.slots.slotCells.rows);
		const spin = (delta:number)=>{
			for(const i in slotMainColumns) {
				const index = Number(i);
				const col = slotMainColumns[index];

				if(isReset[index]) {
					if(Math.floor(col.position.y) <= 0) {
						buildSlotCollSecondElements(isReset);

						if(spinCount[index]===(5-Number(index))) {
							setColIsSpin({index, state:false});
							spinCount[index]=0;
							store.sound.sounds.spin_col_stop!.play();
							col.position.setY(0);

							slotMainColumns[index].children[0].visible=false;
							slotMainColumns[index].children[2].visible=false;
						}

						isReset[index]=false;
					}
				}

				if(col.position.y <= -initPos) {
					col.position.setY(initPos);
					buildSlotCollMainElements(index);

					if(!spinCount[index]) spinCount[index]=0;
					spinCount[index]++;
					setSpinCounter(spinCount);

					isReset[index]=true;
				}

				if($store.getState().slots.collIsSpin[Number(i)]) col.translateY(-delta * mainSpinSpeed);
			}
		}

		// ONCE IN LOOP ==========================================
		const onceSpinStart =()=>{
			if(SpinStopped) {
				SpinStopped = false;
				SpinStarted = true;
				store.sound.sounds.spin_start!.play();
			}
		}
		const onceSpinStop =()=>{
			if(SpinStarted) {
				SpinStarted = false;
				visibleSecondSections(true);
				SpinStopped = true;
			}
		} // ====================================================

		const render =()=>{
			if(composer) {
				composer.render();
				Postprocess.render();
			}
			else {
				renderer.render( scene, camera );
			}
		}
		
		init();
		buildSlotPortal();
		animate();
		
		return () => {
			window.removeEventListener("resize", (e)=>{ onResize(renderer) });
		}
	}, [null])

	const onSpin =()=>{
		setMainPortalIsStarting(true);
	}

	const visibleSecondSections = (bool:boolean) => {
		slotMainColumns.forEach((col)=>{
			col.children.forEach((section,index)=>{
				if(index!==1) section.visible = bool;
			})
		})
	}
	const setAllColsIsReset = (bool:boolean) => {
		for(const i in slotMainColumns) { isReset[Number(i)] = bool; }
	}
	
	const setBounceAllColls = (bool:boolean) => {
		for(const i in slotMainColumns) {
			setCollIsBounced({index:Number(i),state:bool});
		}
	}
	const setCollAllStarting = (bool:boolean) => {
		for(const i in slotMainColumns) {
			// setCollIsStarting({index:Number(i),state:bool});
		}
	}
	const setStoppedAllColls = (bool:boolean) => {
		for(const i in slotMainColumns) {
			setCollIsStopped({index:Number(i),state:bool});
		}
	}
	
	return (
		<div id={"main"} className={style.app}>
			<Layer_GUI onSpin={onSpin}/>
			{ Device.isMobile() && Device.isIOS() &&
				<div className={style.ios_scroll_crunch}/>
			}
		</div>
	)
}