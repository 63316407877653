
export type T_STORAGE_ELEMENT = {
	t: 'o'|'b'|'s'|'n',
	v: any
}

export default class Storage {
	static setLocalStorageItem(key:string, value:any) {
		localStorage.setItem(key, JSON.stringify({t:(typeof value)[0],v:value}))
	}
	static getLocalStorageItem(key:string) {
		const storageEl = localStorage.getItem(key)
		return storageEl
			? (JSON.parse(storageEl) as T_STORAGE_ELEMENT).v
			: null;
	}
}