import style from "./style.module.scss";

const Win = () => {
    return <div className={style.bet}>
        <div className={`${style.info} roboto-mono-400`}>
            <p className={style.label}>WIN</p>
            <p className={`${style.value} roboto-condensed-700`}>0.20</p>
        </div>
    </div>
};

export default Win;