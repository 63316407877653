import style from "./style.module.scss";

const Burger = () => {
    return <div className={style.burger}>
        <p className={style.el}></p>
        <p className={style.el}></p>
        <p className={style.el}></p>
    </div>
};

export default Burger;