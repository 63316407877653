import style from "./style.module.scss";
import {$store} from "../../store";
import {useStore} from "effector-react";

const Balance = () => {
    const store = useStore($store);
    return <div className={style.balance}>
        <div className={`${style.info} roboto-mono-400`}>
            <p className={style.label}>BALANCE</p>
            <p className={`${style.value} roboto-condensed-700`}>{store.app.balance.toFixed(2)}</p>
        </div>
    </div>
};

export default Balance;