import { createStore, createEvent } from "effector";
import {Audio, AudioListener} from "three";
import Storage from "../core/storage";

const IS_SOUND_ENABLED = "snd";

export type Sound = {
	enabled: boolean,
	soundListenerObject: AudioListener,
	
	sounds: {
		background_musik: Audio | null,
		spin_start: Audio | null,
		spin_col_stop: Audio | null
	}
}

const mock = {
	enabled: Storage.getLocalStorageItem(IS_SOUND_ENABLED) || false,
	soundListenerObject: new AudioListener(),

	sounds: {
		background_musik: null,
		spin_start: null,
		spin_col_stop: null,
	}
}

export const setSoundEnabled = createEvent<boolean>();
export const setSoundListenerObject = createEvent<AudioListener>();
export const setBackgroundMusik = createEvent<Audio | null>();
export const setSpinStart = createEvent<Audio | null>();
export const setSpinColStop = createEvent<Audio | null>();
export const clear = createEvent();

export const $sound = createStore<Sound>(mock)
	.on(setSoundEnabled, (state, enabled:boolean) => {
		Storage.setLocalStorageItem(IS_SOUND_ENABLED, enabled);
		return {...state, enabled};
	})
	.on(setSoundListenerObject, (state, soundListenerObject:AudioListener) => { return {...state, soundListenerObject}; })
	.on(setBackgroundMusik, (state, sound:Audio | null) => { state.sounds.background_musik=sound; return {...state }; })
	.on(setSpinStart, (state, sound:Audio | null) => { state.sounds.spin_start=sound; return {...state }; })
	.on(setSpinColStop, (state, sound:Audio | null) => { state.sounds.spin_col_stop=sound; return {...state }; })
	.reset(clear);
