// @ts-ignore
import { LUTCubeLoader } from 'three/addons/loaders/LUTCubeLoader.js';
// @ts-ignore
import { LUT3dlLoader } from 'three/addons/loaders/LUT3dlLoader.js';
// @ts-ignore
import { LUTImageLoader } from 'three/addons/loaders/LUTImageLoader.js';

import {
	OrthographicCamera,
	PerspectiveCamera,
	Scene,
	WebGLRenderer
} from "three";

import {GUI} from "dat.gui";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import Shaders from "./shaders";
import {LUTPass} from "three/examples/jsm/postprocessing/LUTPass";
import {$store} from "../store";

const params = {
	shader3DLut: {
		enabled: true,
		lut: 'Bourbon 64.CUBE',
		intensity: 0
	}
};
const options: any = {
	shader3DLutMap: {
		'Bourbon 64.CUBE': null,
		'Chemical 168.CUBE': null,
		'Clayton 33.CUBE': null,
		'Cubicle 99.CUBE': null,
		'Remy 24.CUBE': null,
		'Presetpro-Cinematic.3dl': null,
		'NeutralLUT': null,
		'B&WLUT': null,
		'NightLUT': null
	}
};

let isInited:boolean = false;

export default class Postprocess {
	static lutPass:LUTPass;
	public static init = (
		renderer:WebGLRenderer,
		scene:Scene,
		camera:OrthographicCamera|PerspectiveCamera,
		composer:EffectComposer,
		gui:GUI,
		render:Function) =>
	{
		Object.keys( options.shader3DLutMap ).forEach( name => {
			const lutsPath = `${process.env.PUBLIC_URL}/static/luts/${name}`;
			if ( /\.CUBE$/i.test( name ) ) {
				new LUTCubeLoader()
					.load( lutsPath, ( result:any )=>{
						options.shader3DLutMap[ name ] = result;
					} );
			} else if ( /\LUT$/i.test( name ) ) {
				new LUTImageLoader()
					.load( `${lutsPath}.png`, ( result:any )=>{
						options.shader3DLutMap[ name ] = result;
					} );
			} else {
				new LUT3dlLoader()
					.load( lutsPath, ( result:any )=>{
						options.shader3DLutMap[ name ] = result;
					} );
			}
		} );

		if(Postprocess.lutPass) {
			// ..
		} else {
			Postprocess.lutPass = new LUTPass({lut:options.shader3DLutMap[params.shader3DLut.lut], intensity:1});
		}

		composer.addPass( Postprocess.lutPass );
		// =====================================
		return composer;
	}

	public static appendGUI = (gui:GUI, scene:Scene)=>{
		if(isInited) return
		else isInited=true;

		if(gui) {
			const LutFolder = gui.addFolder( '3DLut shader' );
			LutFolder.add( params.shader3DLut, 'enabled' );
			LutFolder.add( params.shader3DLut, 'lut', Object.keys( options.shader3DLutMap ) );
			LutFolder.add( params.shader3DLut, 'intensity' ).min( 0 ).max( 1 );

			LutFolder.open();
		}
	}

	public static render() {
		if(Postprocess.lutPass) {
			Postprocess.lutPass.enabled = params.shader3DLut.enabled;
			Postprocess.lutPass.intensity = params.shader3DLut.intensity;
			if ( options.shader3DLutMap[ params.shader3DLut.lut ] ) {
				const lut = options.shader3DLutMap[ params.shader3DLut.lut ];
				Postprocess.lutPass.lut = lut.texture3D;
			}
		}
	}
}

export const LUTShader = {
	name: 'LUTShader',
	uniforms: {
		lut: { value: null },
		lutSize: { value: 0 },
		tDiffuse: { value: null },
		intensity: { value: 1.0 },
	},
	vertexShader: Shaders.list.LUTShader

};