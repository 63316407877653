import Display from "./display";
import {
	Audio,
	AudioListener,
	AudioLoader,
	Box3,
	Euler,
	Mesh,
	MeshBasicMaterial,
	OrthographicCamera,
	PlaneGeometry,
	Scene,
	TextureLoader,
	Vector3,
} from "three";
import {$store} from "../store";

export default class Three {
	public static SetSceneBackground(scene:any, image:string): void {
		const img = new Image();
		img.onload = ()=>{
			scene.background = new TextureLoader().load(img.src)
			scene.background.userData = {img:{ width:img.width, height:img.height }}
			Three.UpdateSceneBackground(scene);
		}
		img.src = image;
	}
	
	public static UpdateSceneBackground(scene:any) {
		if (scene.background) {
			const size = Display.getWindowSize();
			const factor = (scene.background.userData.img.width / scene.background.userData.img.height) / (size.width / size.height);
			
			scene.background.offset.x = factor > 1 ? (1 - 1 / factor) / 2 : 0;
			scene.background.offset.y = factor > 1 ? 0 : (1 - factor) / 2;
			
			scene.background.repeat.x = factor > 1 ? 1 / factor : 1;
			scene.background.repeat.y = factor > 1 ? 1 : factor;
		}
	}
	
	public static GetMeshSize(mesh:Mesh):Vector3 {
		let vec = new Vector3();
		const box = new Box3();
		mesh.geometry.computeBoundingBox();
		if (mesh.geometry.boundingBox instanceof Box3) {
			box.copy(mesh.geometry.boundingBox).applyMatrix4(mesh.matrixWorld);
		}
		box.getSize(vec);
		return vec;
	}
	
	public static createPlane( material:MeshBasicMaterial, width:any, height:any, pos:any, rot:Euler= new Euler(0, 0, 0) ) {
		
		const geometry = new PlaneGeometry( width, height, 1, 1 );
		const mesh = new Mesh( geometry, material );
		mesh.position.copy( pos );
		mesh.rotation.copy( rot );
		
		return mesh;
	}
	
	public static createPlaneAndAdd( parent:Scene|Mesh, material:MeshBasicMaterial, width:any, height:any, pos:any, rot:Euler= new Euler(0, 0, 0) ) {
		
		const geometry = new PlaneGeometry( width, height, 1, 1 );
		const mesh = new Mesh( geometry, material );
		mesh.position.copy( pos );
		mesh.rotation.copy( rot );
		parent.add( mesh );
		
		return mesh;
	}
	
	public static audioInit = (camera:OrthographicCamera): AudioListener => {
		const listener = $store.getState().sound.soundListenerObject;
		camera.add(listener);
		return listener;
	};
	public static createSound(file:string, vol?:number) {
		const listener = $store.getState().sound.soundListenerObject;
		const audioLoader = new AudioLoader();
		const sound = new Audio(listener);

		audioLoader.load(file, (buffer) => {
			sound.setBuffer(buffer);
			sound.setVolume(vol||.5);
		});

		return sound;
	}

}