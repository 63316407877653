import style from "./style.module.scss";
// @ts-ignore
import WebGL from "three/addons/capabilities/WebGL.js";
import React from "react";
export default function Error_WebGL(){
	const is_webgl1:boolean = !!WebGL.isWebGLAvailable();
	const is_webgl2:boolean = !!WebGL.isWebGL2Available();
	
	return (
		<div className={`${style.webgl} roboto-mono-500`}>
			<div>WebGL 1: {is_webgl1 ? <a>SUPPORTED</a> : <a>NOT SUPPORTED</a>}</div>
			<div>WebGL 2: {is_webgl2 ? <a>SUPPORTED</a> : <a>NOT SUPPORTED</a>}</div>
		</div>
	)
}