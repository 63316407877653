import style from "./style.module.scss";
import bet from "../../../img/icon/bet.svg";
import {useStore} from "effector-react";
import {$slots, setSpinPriceIndex} from "../../../store/slots";
import {$store} from "../../../store";
import {useState} from "react";

const Bet = () => {
    const store = useStore($store);
    const slots = useStore($slots);
    
    const [betMenuIsOpened, setBetMenuIsOpened] = useState(false);
    
    return <div className={style.bet}>
        <div className={style.icon} onClick={()=>{setBetMenuIsOpened(!betMenuIsOpened);}}>
            <img src={bet} alt="bet"/>
            <div className={style.spoiler}>
                { betMenuIsOpened &&
                    store.slots.spinPrices.map((preset, index) => {
                        return <div className={`${style.icon} roboto-mono-400`} onClick={()=>{onPreset(index)}}>
                            {`${preset} USD`}
                        </div>
                    })
                }
            
            </div>
        </div>
        <div className={`${style.info} roboto-mono-400`}>
            <p className={style.label}>BET</p>
            <p className={`${style.value} roboto-condensed-700`}>{slots.spinPrices[slots.spinPriceIndex].toFixed(2)}</p>
        </div>
    </div>
    
    function onPreset (index:number) {
        setBetMenuIsOpened(false);
        setSpinPriceIndex(index);
    }
};

export default Bet;