import style from "./style.module.scss";
import arrow from "../../../../img/arrows/reload.svg";

import {setColIsSpin, setIsSpin} from "../../../../store/slots";

import {$store} from "../../../../store";
import {useStore} from "effector-react";
import Utils from "../../../../core/utils";
import {setBalance} from "../../../../store/app";

const Spin = (params:{onSpin:Function}) => {
    const store = useStore($store);
    return <div className={`${style.spin} ${store.slots.isSpin ? style.disabled:''}`} onClick={onSpin}>
        <img className={style.arrow} src={arrow} alt="bet"/>
    </div>
    
    function onSpin() {
        params?.onSpin();
    }
};

export default Spin;