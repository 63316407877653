import {
	ACESFilmicToneMapping, AgXToneMapping,
	CineonToneMapping, CustomToneMapping,
	LinearToneMapping, NeutralToneMapping,
	NoToneMapping,
	OrthographicCamera,
	PerspectiveCamera,
	ReinhardToneMapping,
	Scene, ShaderChunk,
	WebGLRenderer
} from "three";
import {GUI} from "dat.gui";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";

const params = {
	exposure: 1,
	toneMapping: 'Linear',
	blurriness: 4.3,
	intensity: 1
};
let renderer_ref: WebGLRenderer;

let isInited:boolean = false;

const options: any = {
	toneMappingOptions: {
		None: NoToneMapping,
		Linear: LinearToneMapping,
		Reinhard: ReinhardToneMapping,
		Cineon: CineonToneMapping,
		ACESFilmic: ACESFilmicToneMapping,
		AgX: AgXToneMapping,
		Neutral: NeutralToneMapping,
		Custom: CustomToneMapping,
	}
};

export default class Tonemap {
	public static init = (
		renderer:WebGLRenderer,
		scene:Scene,
		camera:OrthographicCamera|PerspectiveCamera,
		composer:EffectComposer
	) => {
		renderer_ref = renderer;

		renderer.toneMapping = options.toneMappingOptions[params.toneMapping];
		renderer.toneMappingExposure = params.exposure;
		scene.backgroundBlurriness = params.blurriness;

		ShaderChunk.tonemapping_pars_fragment = ShaderChunk.tonemapping_pars_fragment.replace(
			'vec3 CustomToneMapping( vec3 color ) { return color; }',
			`#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
				float toneMappingWhitePoint = 1.0;
				vec3 CustomToneMapping( vec3 color ) {
					color *= toneMappingExposure;
					return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
				}`
		);

		composer.addPass(new RenderPass(scene, camera));
		return composer;
	}

	public static guiExposure:any = null;
	public static updateGUI = (folder:GUI)=>{
		if ( Tonemap.guiExposure !== null ) {
			Tonemap.guiExposure.remove();
			Tonemap.guiExposure = null;
		}
		if ( params.toneMapping !== 'None' ) {
			Tonemap.guiExposure = folder.add( params, 'exposure', 0, 2 )
				.onChange( function () {
					renderer_ref.toneMappingExposure = params.exposure;
				} );
		}
	}

	public static appendGUI = (gui:GUI, scene:Scene)=>{
		if(isInited) return
		else isInited=true;

		if(gui) {
			const toneMappingFolder = gui.addFolder( 'tone mapping' );
			toneMappingFolder.add( params, 'toneMapping', Object.keys( options.toneMappingOptions ) )
				.onChange( function () {
					Tonemap.updateGUI( toneMappingFolder );
					renderer_ref.toneMapping = options.toneMappingOptions[ params.toneMapping ];
				} );

			const backgroundFolder = gui.addFolder( 'background' );
			backgroundFolder.add( params, 'blurriness', 0, 1 )
				.onChange( function ( value ) {
					scene.backgroundBlurriness = value;
				} );

			backgroundFolder.add( params, 'intensity', 0, 1 )
				.onChange( function ( value ) {
					scene.backgroundIntensity = value;
				} );

			Tonemap.updateGUI( toneMappingFolder );
			gui.open();
		}
	}



}