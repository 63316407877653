import { createStore, createEvent } from "effector";
import Device from "../core/device";

export type App = {
	balance: number,

	isDev: boolean,
	
	appIsMobile: boolean,
	isSmallScreen: boolean,
	isLandscape: boolean,
	loadedImagesCount: number,
	isAllLoaded: boolean
}

const mock = {
	balance: 20000.50,

	isDev: false,
	
	appIsMobile: false,
	isSmallScreen: false,
	isLandscape: true,
	loadedImagesCount: 0,
	isAllLoaded: false
}

export const initApp = createEvent<App>();
export const setIsDev = createEvent<boolean>();
export const setBalance = createEvent<number>();
export const setAppIsMobile = createEvent<boolean>();
export const setIsSmallScreen = createEvent<boolean>();
export const setIsLandscape = createEvent<boolean>();
export const addLoadedImagesCount = createEvent();
export const setIsAllLoaded = createEvent<boolean>();
export const clear = createEvent();

export const $app = createStore<App>(mock)
	.on(initApp, (state, newState:App) => { return { ...state, ...newState } })
	.on(setAppIsMobile, (state, appIsMobile:boolean) => { return {...state, appIsMobile}; })
	.on(setIsDev, (state, isDev:boolean) => { return {...state, isDev}; })
	.on(setBalance, (state, balance:number) => { return {...state, balance}; })
	.on(setIsSmallScreen, (state, isSmallScreen:boolean) => { return {...state, isSmallScreen}; })
	.on(setIsLandscape, (state, isLandscape:boolean) => { return {...state, isLandscape}; })
	.on(addLoadedImagesCount, (state) => {return {...state, loadedImagesCount: state.loadedImagesCount+1 }; })
	.on(setIsAllLoaded, (state, isAllLoaded:boolean) => {return {...state, isAllLoaded }; })
	.reset(clear);
