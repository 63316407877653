import {IUniform, ShaderMaterial, UniformsUtils} from 'three';
import {FullScreenQuad, Pass} from "three/examples/jsm/postprocessing/Pass";

export default class Shaders {
	public static list = {
		LUTShader: {
			name: 'LUTShader',
			uniforms: {
				lut: { value: null },
				lutSize: { value: 0 },
				tDiffuse: { value: null },
				intensity: { value: 1.0 },
			},
			vertexShader: /* glsl */`
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}
		`,
			fragmentShader: /* glsl */`
			uniform float lutSize;
			uniform sampler3D lut;
			varying vec2 vUv;
			uniform float intensity;
			uniform sampler2D tDiffuse;
			void main() {
				vec4 val = texture2D( tDiffuse, vUv );
				vec4 lutVal;
				// pull the sample in by half a pixel so the sample begins
				// at the center of the edge pixels.
				float pixelWidth = 1.0 / lutSize;
				float halfPixelWidth = 0.5 / lutSize;
				vec3 uvw = vec3( halfPixelWidth ) + val.rgb * ( 1.0 - pixelWidth );
				lutVal = vec4( texture( lut, uvw ).rgb, val.a );
				gl_FragColor = vec4( mix( val, lutVal, intensity ) );
			}
		`,
		}
	};
}

class ShaderPass extends Pass {
	private readonly textureID: any;
	private readonly uniforms: { [p: string]: IUniform; } | undefined;
	private readonly material: ShaderMaterial | undefined;
	private fsQuad: any;

	constructor( shader:any, textureID:any ) {
		super();
		this.textureID = ( textureID !== undefined ) ? textureID : 'tDiffuse';

		if ( shader instanceof ShaderMaterial ) {
			this.uniforms = shader.uniforms;
			this.material = shader;
		} else if ( shader ) {
			this.uniforms = UniformsUtils.clone( shader.uniforms );
			this.material = new ShaderMaterial( {
				name: ( shader.name !== undefined ) ? shader.name : 'unspecified',
				defines: Object.assign( {}, shader.defines ),
				uniforms: this.uniforms,
				vertexShader: shader.vertexShader,
				fragmentShader: shader.fragmentShader
			} );
		}
		this.fsQuad = new FullScreenQuad( this.material );
	}

	override render( renderer:any, writeBuffer:any, readBuffer:any /*, deltaTime, maskActive */ ) {
		if ( this.uniforms && this.uniforms[ this.textureID ] ) {
			this.uniforms[ this.textureID ].value = readBuffer.texture;
		}

		this.fsQuad.material = this.material;

		if ( this.renderToScreen ) {
			renderer.setRenderTarget( null );
			this.fsQuad.render( renderer );
		} else {
			renderer.setRenderTarget( writeBuffer );
			if ( this.clear ) renderer.clear( renderer.autoClearColor, renderer.autoClearDepth, renderer.autoClearStencil );
			this.fsQuad.render( renderer );
		}
	}

	dispose() {
		if(this.material) this.material.dispose();
		this.fsQuad.dispose();
	}
}
