import style from "./style.module.scss";
import sound_on from "../../../img/icon/sound_max.svg";
import sound_off from "../../../img/icon/sound_off.svg";
import {$store} from "../../../store";
import {useStore} from "effector-react";
import {setSoundEnabled} from "../../../store/sound";
import {useLayoutEffect} from "react";
import Storage from "../../../core/storage";

const IS_SOUND_ENABLED = "snd";

const Sound = () => {
    const store = useStore($store);
	useLayoutEffect(() => {
		onSound(!!Storage.getLocalStorageItem(IS_SOUND_ENABLED) || false);
	}, []);

    return <div className={style.sound} onClick={()=>{ onSound(!store.sound.enabled) }}>
		{ store.sound.enabled
			? <img src={sound_on} alt="sound_on"/>
			: <img src={sound_off} alt="sound_off"/>
		}
	</div>
	
	function onSound(enabled:boolean) {
		store.sound.soundListenerObject.setMasterVolume(enabled ? 1 : 0);
		setSoundEnabled(enabled);
	}
};

export default Sound;